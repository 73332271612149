import { useState } from "react";
import { httpClient } from "../Api";
import { useAuth } from "../../authentication";
import { getApi, postApi, putApi } from "../Axios";

export const usePatient = () => {
  const { getLogout } = useAuth();

  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  // const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const fetchStart = () => {
    setLoading(true);
    setError("");
    setSuccessMessage("");
  };

  const fetchSuccess = (message) => {
    setLoading(false);
    setError("");
    setSuccessMessage(message);
  };

  const fetchError = (error) => {
    setLoading(false);
    setError(error);
    setSuccessMessage("");
  };

  const addPatient = (patient, callbackFun) => {
    postApi(
      `PatientMenu/PatientRegistr/Post`,
      patient,
      callbackFun,
      "msg",
      getLogout,
      true
    );
  };

  const getPatients = (hsptID, callbackFun) => {
    fetchStart();
    httpClient
      .post(`PatientMenu/PatientRegistr/${hsptID}`)
      .then(({ data }) => {
        fetchSuccess("");
        // console.log("Get Patients =", data);
        if (callbackFun) callbackFun(data);
      })
      .catch(function (error) {
        fetchError(error.message);
        // if (callbackFun) callbackFun(error.message);
      });
  };

  const getPatientTocken = (VisitID, PaymentID, callbackFun) => {
    getApi(
      `DoctorTreatment/DrDashbord/GetPatientToken/${VisitID},${PaymentID}`,
      callbackFun,
      "data",
      getLogout,
      true
    );
  };

  const searchPatient = (value, callbackFun) => {
    getApi(
      `PatientMenu/SearchPatient/Search?sFind=${value}`,
      callbackFun,
      "data",
      getLogout,
      true
    );
  };

  const searchPatientByMr = (mr, callbackFun) => {
    getApi(
      `PatientMenu/PatientRegistr/GetAlreadyRegPatient/${mr}`,
      callbackFun,
      "data",
      getLogout,
      true
    );
  };

  const getCountries = (callbackFun) => {
    fetchStart();
    httpClient
      .get(`PatientMenu/PatientRegistr/Getcountry`)
      .then(({ data }) => {
        fetchSuccess(data);
        if (callbackFun) callbackFun(data);
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const getCitiesByCountryId = (countryID, callbackFun) => {
    getApi(
      `PatientMenu/PatientRegistr/GetCity/${countryID}`,
      callbackFun,
      "data",
      getLogout,
      false
    );
  };

  const getDepts = (callbackFun) => {
    getApi(
      `PatientMenu/PatientRegistr/GetDepartment`,
      callbackFun,
      "data",
      getLogout,
      true
    );
  };

  const getDoctorsByDeptId = (deptId, callbackFun) => {
    getApi(
      `PatientMenu/PatientRegistr/GetDoctors/${deptId}`,
      callbackFun,
      "data",
      getLogout,
      true
    );
  };

  const getPtntCategories = (callbackFun) => {
    getApi(
      `PatientMenu/PatientRegistr/GetByType`,
      callbackFun,
      "data",
      getLogout,
      false
    );
  };

  const updatePatient = (newPatient, callbackFun) => {
    putApi(
      `PatientMenu/PatientRegistr/UpdatePatient/${newPatient.mrNumber}`,
      newPatient,
      callbackFun,
      "msg",
      getLogout,
      true
    );
  };

  const checkCnicPatient = (Cnic, IDOwner, callbackFun) => {
    Cnic = Cnic.replace(/[-]/g, "");
    getApi(
      `PatientMenu/PatientRegistr/CheckCNIC/${Cnic},${IDOwner}`,
      callbackFun,
      "msg",
      getLogout,
      true
    );
  };

  const checkCellPatient = (ContactNo, ContactOwner, callbackFun) => {
    ContactNo = ContactNo.replace(/[  %-]/g, "");
    getApi(
      `PatientMenu/PatientRegistr/CheckCell/${ContactNo},${ContactOwner}`,
      callbackFun,
      "msg",
      getLogout,
      true
    );
  };

  return {
    addPatient,
    getPatients,
    searchPatient,
    searchPatientByMr,
    getCountries,
    getCitiesByCountryId,
    getDepts,
    getDoctorsByDeptId,
    getPtntCategories,
    updatePatient,
    checkCnicPatient,
    checkCellPatient,
    getPatientTocken,
    isLoading,
    error,
    successMessage,
  };
};
