import { httpClient } from "../../Api";
import {
  fetchStart as gFetchStart,
  fetchSuccess as gFetchSuccess,
  fetchError as gFetchError,
  showMessage as gShowMessage,
  hideMessage as gHideMessage,
} from "../../../appRedux/actions";
import { useDispatch } from "react-redux";
import { getApi, putApi, deleteApi, postApi } from "../../Axios";
import { getApi as newGetApi } from "../../Axios_Dev";
import { useAuth } from "../../../authentication";

export const usePackage = () => {
  const { getLogout } = useAuth();
  const dispatch = useDispatch();

  const fetchStart = () => {
    dispatch(gFetchStart());
  };

  const fetchSuccess = (message) => {
    dispatch(gFetchSuccess());
    dispatch(gShowMessage(message));
  };

  const fetchError = (error) => {
    dispatch(gFetchError(error));
  };

  const addPackage = (packages, callbackFun) => {
    fetchStart();
    httpClient
      .post(`Administration/ManagePackage/SavePackage`, packages)
      .then(({ data }) => {
        fetchSuccess("Added Successfully!");
        if (callbackFun)
          getPackages(packages.sponserID, packages.packageType, callbackFun);
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const assignServiceToPackage = (model, callbackFun) => {
    fetchStart();
    httpClient
      .post(
        `Administration/ManagePackage/SaveServicesAganistSponsersAndPackages`,
        model
      )
      .then(({ data }) => {
        fetchSuccess(data);
        if (callbackFun) callbackFun(data);
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const getPackages = (SponserID, PackageType, callbackFun) => {
    fetchStart();
    httpClient
      .get(
        `Administration/ManagePackage/GetPackageDeatil/${SponserID},${PackageType}`
      )
      .then(({ data }) => {
        fetchSuccess("");
        if (data != "No Record Found") if (callbackFun) callbackFun(data);
        if (data == "No Record Found") if (callbackFun) callbackFun(null);
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const getPackageType = (callbackFun) => {
    fetchStart();
    httpClient
      .get(`Administration/ManagePackage/GetPackageAndServiceType`)
      .then(({ data }) => {
        fetchSuccess("");
        if (data != "No Record Found") if (callbackFun) callbackFun(data);
        if (data == "No Record Found") if (callbackFun) callbackFun(null);
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  // /api/Administration/ManagePackage/UpdatePackage/{PackageID}
  const updatePackage = (model, callbackFun) => {
    fetchStart();
    httpClient
      .put(
        `Administration/ManagePackage/UpdatePackage/${model.packageID}`,
        model
      )
      .then(({ data }) => {
        fetchSuccess("Updated Successfully!");
        if (callbackFun)
          getPackages(model.sponserID, model.packageType, callbackFun);
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };
  const getServicesRateByGroupId = (MainGroupId, callbackFun) => {
    getApi(
      `Administration/ServicesRate/GetServicesRateByGroupId/${MainGroupId}`,
      callbackFun,
      "data",
      getLogout,
      false
    );
  };
  const getSericesGroupWiseNotAssigned = (
    SponserID,
    PackageID,
    PackageType,
    ServiceGroupID,
    ServiceId,
    callbackFun
  ) => {
    getApi(
      `Administration/ManagePackage/GetSericesGroupWiseNotAssigned/${SponserID},${PackageID},${PackageType},${ServiceGroupID},${ServiceId}`,
      callbackFun,
      "data",
      getLogout,
      false
    );
  };
  const getSericesGroupWiseAssignedServices = (
    SponserID,
    PackageID,
    PackageType,
    ServiceGroupID,
    ServiceId,
    callbackFun
  ) => {
    getApi(
      `Administration/ManagePackage/GetSericesGroupWiseAssignedServices/${SponserID},${PackageID},${PackageType},${ServiceGroupID},${ServiceId}`,
      callbackFun,
      "data",
      getLogout,
      false
    );
  };

  const saveServicesAganistSponsersAndPackages = (payload, callbackFun) => {
    postApi(
      `Administration/ManagePackage/SaveServicesAganistSponsersAndPackages`,
      payload,
      callbackFun,
      "msg",
      getLogout,
      true
    );
  };

  const updateApprovalRequired = (
    PkgServiceID,
    ApprovalRequired,
    payload,
    callbackFun
  ) => {
    putApi(
      `Administration/ManagePackage/UpdateApprovalRequired/${PkgServiceID},${ApprovalRequired}`,
      payload,
      callbackFun,
      "msg",
      getLogout,
      true
    );
  };
  const makeServiceActiveInactive = (
    PkgServiceID,
    Isactive,
    payload,
    callbackFun
  ) => {
    putApi(
      `Administration/ManagePackage/MakeServiceActiveInactive/${PkgServiceID},${Isactive}`,
      payload,
      callbackFun,
      "msg",
      getLogout,
      true
    );
  };
  const deleteServicesFromSponserPackage = (PkgServiceID, callbackFun) => {
    deleteApi(
      `Administration/ManagePackage/DeleteServicesFromSponserPackage/${PkgServiceID}`,
      callbackFun,
      "msg",
      getLogout,
      true
    );
  };

  return {
    addPackage,
    getPackages,
    updatePackage,
    getPackageType,
    getServicesRateByGroupId,
    assignServiceToPackage,
    getSericesGroupWiseNotAssigned,
    getSericesGroupWiseAssignedServices,
    saveServicesAganistSponsersAndPackages,
    updateApprovalRequired,
    makeServiceActiveInactive,
    deleteServicesFromSponserPackage,
  };
};
