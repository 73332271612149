import {
  BankOutlined,
  CalculatorOutlined,
  ControlOutlined,
  DashboardOutlined,
  DeliveredProcedureOutlined,
  GiftOutlined,
  GoldOutlined,
  LayoutOutlined,
  MedicineBoxOutlined,
  NodeIndexOutlined,
  PayCircleOutlined,
  RadarChartOutlined,
  SafetyCertificateOutlined,
  SearchOutlined,
  SettingOutlined,
  SmileOutlined,
  TagsOutlined,
  TeamOutlined,
  UserAddOutlined,
} from "@ant-design/icons";

export const MENU = {
  UserAddOutlined: <UserAddOutlined />,
  TeamOutlined: <TeamOutlined />,
  SettingOutlinedSpin: <SettingOutlined spin rotate={5} />,
  SmileOutlined: <SmileOutlined />,
  NodeIndexOutlined: <NodeIndexOutlined />,
  MedicineBoxOutlined: <MedicineBoxOutlined />,
  ControlOutlined: <ControlOutlined />,
  PayCircleOutlined: <PayCircleOutlined />,
  GoldOutlined: <GoldOutlined />,
  DeliveredProcedureOutlined: <DeliveredProcedureOutlined />,
  SearchOutlined: <SearchOutlined />,
  BankOutlined: <BankOutlined />,
  DashboardOutlined: <DashboardOutlined />,
  RadarChartOutlined: <RadarChartOutlined />,
  LayoutOutlined: <LayoutOutlined />,
  TagsOutlined: <TagsOutlined />,
  GiftOutlined: <GiftOutlined />,
  CalculatorOutlinedSpin: <CalculatorOutlined spin rotate={180} />,
  CalculatorOutlined: <CalculatorOutlined />,
  SafetyCertificateOutlined: <SafetyCertificateOutlined />,
};
