import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Common from "./Common";
import AddGroup from "./AddGroup";
import AddHospital from "./AddHospital";
import AddDepartment from "./AddDepartment";
import AddSubDepartment from "./AddSubDepartment";
import PatientVisits from "./PatientVisits";
import MenuAndPage from "./MenuAndPage";
import AddEmployee from "./administration/AddEmployee";
import AddRole from "./administration/AddRole";
import CreateLogin from "./administration/CreateLogin";
import FavDiagnosis from "./FavDiagnosis";
import FavInvestigation from "./FavInvestigation";
import FavMedication from "./FavMedication";
import FavProcedure from "./FavProcedure";
import AddServiceMainGroup from "./billing/AddServiceMainGroup";
import AddServiceSubGroup from "./billing/AddServiceSubGroup";
import TemplateAssignment from "./TemplateAssignment";
import AssignRolesToUser from "./administration/AssignRolesToUser";
import ServicesSetup from "./billing/ServicesSetup";
import Panels from "./panel&package/Panels";
import Packages from "./panel&package/Packages";
import AssignModulesToRole from "./administration/AssignModulesToRole";
import ServiceRateSetup from "./billing/ServiceRateSetup";
import TemplateSetup from "./TemplateSetup";
import PatientDiag from "./PatientDiag";
import PatientInvest from "./PatientInvest";
import DiscountPolicy from "./billing/DiscountPolicy";
import Admission from "./Admission";
import Observation from "./Observation";
import Login from "./Login";
import InvestVisit from "./InvestVisit";
import Shifts from "./Shifts";
import PatientVisitToken from "./PatientVisitToken";
import DrDashboard from "./DrDashboard";
import PatientHistory from "./PatientHistory";
import PatientEMR from "./emr/PatientEMR";
import TemplateRetrieval from "./TemplateRetrieval";
import PatientBilling from "./PatientBilling";
import Procurement from "./Procurement";
import { LOGOUT } from "../../constants/ActionTypes";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    common: Common,
    addGroup: AddGroup,
    addHospital: AddHospital,
    addDepartment: AddDepartment,
    addSubDepartment: AddSubDepartment,
    patientVisits: PatientVisits,
    menuAndPage: MenuAndPage,
    addEmployee: AddEmployee,
    addRole: AddRole,
    createLogin: CreateLogin,
    favDiagnosis: FavDiagnosis,
    favInvestigation: FavInvestigation,
    favMedication: FavMedication,
    favProcedure: FavProcedure,
    addServiceMainGroup: AddServiceMainGroup,
    addServiceSubGroup: AddServiceSubGroup,
    templateAssignment: TemplateAssignment,
    templateSetup: TemplateSetup,
    patientDiag: PatientDiag,
    assignRolesToUser: AssignRolesToUser,
    servicesSetup: ServicesSetup,
    panels: Panels,
    assignModulesToRole: AssignModulesToRole,
    serviceRateSetup: ServiceRateSetup,
    packages: Packages,
    patientInvest: PatientInvest,
    discountPolicy: DiscountPolicy,
    admission: Admission,
    observation: Observation,
    login: Login,
    investVisit: InvestVisit,
    shifts: Shifts,
    patientVisitToken: PatientVisitToken,
    drDashboard: DrDashboard,
    patientHistory: PatientHistory,
    patientEMR: PatientEMR,
    templateRetrieval: TemplateRetrieval,
    patientBilling: PatientBilling,
    procurement: Procurement,
  });

export default createRootReducer;
