import { useAuth } from "../../authentication";
import { postApi, putApi, getApi } from "../Axios";

export const useDepartments = () => {
  const { getLogout } = useAuth();

  const addDepartment = (department, callbackFun) => {
    postApi(
      `Human_Resource/Department/Post`,
      department,
      callbackFun,
      "msg",
      getLogout,
      true
    );
  };

  const getDepartmentsByHospitalId = (callbackFun) => {
    getApi(
      `Human_Resource/Department/GetDeptByHospital`,
      callbackFun,
      "data",
      getLogout,
      false
    );
  };

  const updateDepartment = (department, callbackFun) => {
    putApi(
      `Human_Resource/Department/DeptUpdate`,
      department,
      callbackFun,
      "msg",
      getLogout,
      true
    );
  };

  const getDepartmentTypes = (callbackFun) => {
    getApi(
      `Human_Resource/Department/GetDeptType`,
      callbackFun,
      "data",
      getLogout,
      true
    );
  };

  const getDepartmentsByHospitalID = (hospitalId, callbackFun) => {
    getApi(
      `/DefaultMethods/DefaultMethods?Type=Department&HsptID=${hospitalId}`,
      callbackFun,
      "data",
      getLogout,
      false
    );
  };

  const getSubDepartmentsByHospitalId = (
    hospitalId,
    departmentId,
    callbackFun
  ) => {
    getApi(
      `/DefaultMethods/DefaultMethods?Type=SubDepartment&HsptID=${hospitalId}&DeptID=${departmentId}`,
      callbackFun,
      "data",
      getLogout,
      true
    );
  };

  return {
    addDepartment,
    getDepartmentsByHospitalId,
    updateDepartment,
    getDepartmentTypes,
    getDepartmentsByHospitalID,
    getSubDepartmentsByHospitalId,
  };
};
