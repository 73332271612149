import { useEffect, useState } from "react";
import { useAuth } from "../../../authentication";
import { postApi, putApi, getApi } from "../../Axios";
import { getApi as newGetApi, postApi as newPostApi } from "../../Axios_Dev";
import { message } from "antd";

export const useEmployee = () => {
  const { getLogout } = useAuth();
  const [allEmployeesData, setAllEmployeesData] = useState([]);

  const getData = () => {
    getAllEmployees(0, (data) => {
      setAllEmployeesData(data);
    });
  };

  const getEmployee = (callbackFun) => {
    getApi(`EmployeeReg`, callbackFun, "data", getLogout, false);
  };

  const getDesignation = (LookupType, PType, callbackFun) => {
    newGetApi(
      `Pharmacy/LookUps/GetHsptlLookup/${LookupType},${PType}`,
      callbackFun,
      "data",
      false
    );
  };

  const getGender = (LookupType, PType, callbackFun) => {
    newGetApi(
      `Pharmacy/LookUps/GetHsptlLookup/${LookupType},${PType}`,
      callbackFun,
      "data",
      false
    );
  };

  const getMaritalStatus = (LookupType, PType, callbackFun) => {
    newGetApi(
      `Pharmacy/LookUps/GetHsptlLookup/${LookupType},${PType}`,
      callbackFun,
      "data",
      false
    );
  };

  const getBloodGroup = (LookupType, PType, callbackFun) => {
    newGetApi(
      `Pharmacy/LookUps/GetHsptlLookup/${LookupType},${PType}`,
      callbackFun,
      "data",
      false
    );
  };

  const getReligion = (LookupType, PType, callbackFun) => {
    newGetApi(
      `Pharmacy/LookUps/GetHsptlLookup/${LookupType},${PType}`,
      callbackFun,
      "data",
      false
    );
  };

  const getCountries = (callbackFun) => {
    newGetApi(`Pharmacy/LookUps/GetCountries`, callbackFun, "data", false);
  };

  const getState = (CountryId, callbackFun) => {
    newGetApi(
      `Pharmacy/LookUps/GetStates/${CountryId}`,
      callbackFun,
      "data",
      false
    );
  };

  const getCities = (CountryId, StateId, callbackFun) => {
    newGetApi(
      `Pharmacy/LookUps/GetCities/${CountryId},${StateId}`,
      callbackFun,
      "data",
      false
    );
  };
  const getAllEmployees = (EmpId, callbackFun) => {
    newGetApi(
      `EmployeeReg/GetAllEmployees/${EmpId}`,
      callbackFun,
      "data",
      false
    );
  };
  const getEmployeeDetail = (EmpId, callbackFun) => {
    newGetApi(
      `EmployeeReg/GetEmployeeDetail/${EmpId}`,
      callbackFun,
      "data",
      false
    );
  };
  const addEmployee = (employee, callbackFun) => {
    postApi(`EmployeeReg`, employee, callbackFun, "msg", getLogout, true);
  };

  const updateEmployee = (employee, callbackFun) => {
    putApi(`EmployeeReg`, employee, callbackFun, "msg", getLogout, true);
  };

  const getEmployeePromise = (HsptID, DeptID, SubDeptID, callbackFun) => {
    getApi(
      `DefaultMethods/DefaultMethods?Type=Employee&HsptID=${HsptID}&DeptID=${DeptID}&SubDeptID=${SubDeptID}`,
      callbackFun,
      "data",
      getLogout,
      false
    );
  };

  const onUpdateEmployee = (payload, callbackFun) => {
    upsertEmployee(payload, (msg) => {
      getData();
      message.success(<span>{msg}</span>);
      callbackFun && callbackFun();
    });
  };

  const upsertEmployee = (payload, callbackFun) => {
    newPostApi(
      `EmployeeReg/UpsertEmployee`,
      payload,
      callbackFun,
      "msg",
      getLogout,
      true
    );
  };

  return {
    addEmployee,
    getEmployee,
    updateEmployee,
    getEmployeePromise,
    getDesignation,
    getGender,
    getMaritalStatus,
    getBloodGroup,
    getReligion,
    getCountries,
    getState,
    getCities,
    upsertEmployee,
    getAllEmployees,
    getEmployeeDetail,
    allEmployeesData,
    setAllEmployeesData,
    onUpdateEmployee,
    getData,
  };
};
